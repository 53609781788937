import { graphql, useStaticQuery } from 'gatsby'

export const useQueryAllSiteMetadata = (): readonly Queries.STRAPI_SITE_METADATAEdge[] => {
  const data: Queries.AllStrapiSiteMetadataQuery = useStaticQuery(graphql`
    query AllStrapiSiteMetadata {
      allStrapiSiteMetadata {
        edges {
          node {
            siteName
            siteUrl
            monthYear
            siteEmail
            sitePhone
            languageAcronyms
            domain {
              name
            }
            siteSchedule
            siteScheduleMobile
            subscriptionType1
            priceSubscriptionType1
            subscriptionType2
            priceSubscriptionType2
            subscriptionType3
            priceSubscriptionType3
            subscriptionPeriodDays
            morRegistry
            initialPeriodFee
            subscriptionInitialTrialPeriodDays
            companyName {
              text
              image {
                localFile {
                  publicURL
                }
              }
              activeText
            }
            companyNameTwo {
              text
              image {
                localFile {
                  publicURL
                }
              }
              activeText
            }
            companyNameThree {
              text
              image {
                localFile {
                  publicURL
                }
              }
              activeText
            }
            siteCif {
              text
              image {
                localFile {
                  publicURL
                }
              }
              activeText
            }
            siteEinTwo {
              text
              image {
                localFile {
                  publicURL
                }
              }
              activeText
            }
            siteEinThree {
              text
              image {
                localFile {
                  publicURL
                }
              }
              activeText
            }
            siteAddress {
              text
              image {
                localFile {
                  publicURL
                }
              }
              activeText
            }
            siteAddressTwo {
              text
              image {
                localFile {
                  publicURL
                }
              }
              activeText
            }
            siteAddressTwoFirstPart {
              text
              image {
                localFile {
                  publicURL
                }
              }
              activeText
            }
            siteAddressTwoSecondPart {
              text
              image {
                localFile {
                  publicURL
                }
              }
              activeText
            }
          }
        }
      }
    }
  `)
  return data.allStrapiSiteMetadata.edges as readonly Queries.STRAPI_SITE_METADATAEdge[]
}
