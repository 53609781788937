/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { type GatsbyBrowser, Script } from 'gatsby'
import React from 'react'

import { CookieContextProvider } from './src/modules/shared/cookies/CookieContext'
import { CookiePolicyLayout } from './src/components/layout/CookiePolicyLayout/CookiePolicyLayout'
import { getConfigs } from './src/modules/core/configs/IConfig'
import { getParamsUrl } from './src/services/utils/paramsUrl/paramsUrl'
import { hotjarScript } from './src/services/utils/hotjar'
import { initAmplitude } from './src/modules/shared/amplitude'
import { setTestABCookie } from './src/services/utils/setTestABCookie'
import { setUtm } from './src/services/sem/setUtm'
import { useQueryChatContent } from '@legal/shared/graphql'
import { validateDateGmt } from '@legal/shared/utils/validateDateGmt/validateDateGmt'

import './src/assets/styles/styles.scss'

interface Time {
  hour: number
  minute: number
}
interface ScheduleOfWeek {
  [dayOfWeek: string]: {
    from: Time
    to: Time
  }
}

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  const itemsParamsUrl = getParamsUrl()
  const {
    HOTJAR_ID,
    STRAPI_CONFIG: { LOCALE }
  } = getConfigs()
  setTestABCookie('12415')
  setTestABCookie('16323')
  setTestABCookie('19978')
  initAmplitude()
  itemsParamsUrl && setUtm({ itemsParamsUrl })
  LOCALE === 'en-GB' && hotjarScript({ hotjarID: HOTJAR_ID })
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }): React.JSX.Element => {
  return <CookieContextProvider>{element}</CookieContextProvider>
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = () => {
  if (typeof window !== 'undefined') {
    if (window.history.length === 1 || window.history.length === 2) {
      window.scrollTo(0, 0)
      return false
    }
  }
  return true
}

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  if (location.pathname !== '/contact-us/') {
    document.getElementById('fc_frame')?.classList.add('hide')
  } else {
    document.getElementById('fc_frame')?.classList.remove('hide')
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const { chatContent, businessHours } = useQueryChatContent()
  const { FRESHCHAT_CONFIG, HAS_TRUSTPILOT } = getConfigs()

  const scheduleOfWeek: ScheduleOfWeek = (businessHours ?? []).reduce<ScheduleOfWeek>((acc, item) => {
    const dayOfWeek = item?.dayOfWeek ?? 'Monday'
    acc[dayOfWeek] = {
      from: {
        hour: item?.from?.hour ?? 0,
        minute: item?.from?.minute ?? 0
      },
      to: {
        hour: item?.to?.hour ?? 0,
        minute: item?.to?.minute ?? 0
      }
    }
    return acc
  }, {})
  return (
    <>
      {HAS_TRUSTPILOT && (
        <Script
          id='trustpilot-script'
          type='text/javascript'
          src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
          async
        />
      )}

      {FRESHCHAT_CONFIG.ENABLED && props.location.pathname === '/contact-us/' && (
        <>
          <Script
            id='fcPreChatForm'
            src='https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js'
            onLoad={() => {
              window.preChatTemplate = {
                heading: chatContent?.heading,
                mainbgColor: '#078080',
                maintxColor: '#fff',
                textBanner: chatContent?.textBanner,
                SubmitLabel: chatContent?.submitLabel,
                fields: {
                  field1: {
                    error: chatContent?.inputName?.errorMessages?.error,
                    fieldId: 'name',
                    label: chatContent?.inputName?.label,
                    required: 'yes',
                    type: 'text'
                  },
                  field2: {
                    error: chatContent?.inputEmail?.errorMessages?.error,
                    fieldId: 'email',
                    label: chatContent?.inputEmail?.label,
                    required: 'yes',
                    type: 'email'
                  }
                }
              }
              window.fcSettings = {
                token: FRESHCHAT_CONFIG.TOKEN,
                host: FRESHCHAT_CONFIG.HOST,
                widgetUuid: FRESHCHAT_CONFIG.WIDGET_ID,
                config: {
                  content: {
                    actions: {
                      tab_chat: chatContent?.configActions
                    },
                    headers: {
                      chat: chatContent?.headersChat
                    }
                  },
                  cssNames: {
                    expanded: 'custom_fc_expanded',
                    widget: 'custom_fc_frame'
                  }
                },
                onInit: function () {
                  window.fcPreChatform.fcWidgetInit(window.preChatTemplate)
                }
              }
            }}
          ></Script>
          <Script
            src='https://wchat.eu.freshchat.com/js/widget.js'
            onLoad={() => {
              window.fcWidget.on('widget:loaded', function (resp) {
                window.fcWidget.conversation.setConversationProperties({
                  cf_customer_url: window.location.href
                })
              })
              const businessInTime = validateDateGmt({ businessHoursConfig: scheduleOfWeek })
              if (!businessInTime) {
                document.body.classList.add('freshChat_hide')
              }
            }}
          ></Script>
        </>
      )}
      <CookiePolicyLayout {...props}>{element}</CookiePolicyLayout>
    </>
  )
}
