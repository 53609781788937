import { graphql, useStaticQuery } from 'gatsby'

export const useQueryAllFeaturedProducts = (): readonly Queries.STRAPI_PRODUCT_PAGEEdge[] => {
  const data: Queries.AllStrapiProductFilterIsFeaturedQuery = useStaticQuery(graphql`
    query AllStrapiProductFilterIsFeatured {
      allStrapiProductPage(sort: { linkText: ASC }, filter: { isFeatured: { eq: true } }) {
        edges {
          node {
            category {
              url
            }
            categoryProduct {
              name
              icon {
                url
                localFile {
                  publicURL
                }
              }
              slug
            }
            isFeatured
            popularOrder
            jumbotron {
              title
            }
            linkText
            slug
            footerPopularDocument
            domain {
              name
            }
          }
        }
      }
    }
  `)
  return data.allStrapiProductPage.edges as readonly Queries.STRAPI_PRODUCT_PAGEEdge[]
}
