import { graphql, useStaticQuery } from 'gatsby'

export const useQuerySiteMetadata = (): { metadata: Queries.SiteMetadataDataFragment | undefined } => {
  const data: Queries.SiteMetadataQuery = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          ...SiteMetadataData
        }
      }
    }
  `)
  return { metadata: data.site?.siteMetadata ?? undefined }
}
