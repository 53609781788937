import { graphql, useStaticQuery } from 'gatsby'

import { useConfigs } from '@legal/core/configs'

export const useQueryCookiePolicyBar = (): Queries.STRAPI_COOKIE_POLICY_BAR => {
  const { STRAPI_CONFIG } = useConfigs()
  const { allStrapiCookiePolicyBar } = useStaticQuery(graphql`
    query {
      allStrapiCookiePolicyBar {
        nodes {
          text {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          domain {
            name
          }
        }
      }
    }
  `)
  return allStrapiCookiePolicyBar.nodes.find(
    (node: Queries.STRAPI_COOKIE_POLICY_BAR) => node.domain?.name === STRAPI_CONFIG.DOMAIN
  )
}
