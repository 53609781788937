import { graphql, useStaticQuery } from 'gatsby'

export interface StrapiSearchBar {
  title: string
  placeholder: string
}

export const useQuerySearchbar = (): StrapiSearchBar => {
  const { strapiSearchBar }: { strapiSearchBar: StrapiSearchBar } = useStaticQuery(graphql`
    query {
      strapiSearchBar {
        title
        placeholder
      }
    }
  `)

  return strapiSearchBar
}
