import { graphql, useStaticQuery } from 'gatsby'

export const useQueryAllSearchableProducts = (): readonly Queries.STRAPI_PRODUCT_PAGEEdge[] => {
  const data: Queries.AllStrapiProductQuery = useStaticQuery(graphql`
    query AllStrapiProduct {
      allStrapiProductPage(sort: { linkText: ASC }, filter: { isHiddenSearch: { ne: true } }) {
        edges {
          node {
            category {
              url
            }
            isFeatured
            popularOrder
            jumbotron {
              title
            }
            linkText
            slug
            footerPopularDocument
            domain {
              name
            }
          }
        }
      }
    }
  `)
  return data.allStrapiProductPage.edges as readonly Queries.STRAPI_PRODUCT_PAGEEdge[]
}
