import { graphql, useStaticQuery } from 'gatsby'
import { useConfigs } from '@legal/core/configs'

export const useQueryAllDisclaimer = (): Queries.STRAPI_DISCLAIMER[] => {
  const { STRAPI_CONFIG } = useConfigs()

  const { allStrapiDisclaimer } = useStaticQuery(graphql`
    query {
      allStrapiDisclaimer {
        nodes {
          reference
          text
          domain {
            name
          }
        }
      }
    }
  `)
  return allStrapiDisclaimer.nodes.filter(
    (node: Queries.STRAPI_DISCLAIMER) => node.domain?.name === STRAPI_CONFIG.DOMAIN
  )
}
