import { graphql, useStaticQuery } from 'gatsby'

import { useConfigs } from '@legal/core/configs'

export const useQueryVwoEnableList = (): string[] | null => {
  const { STRAPI_CONFIG } = useConfigs()
  const { strapiHome } = useStaticQuery(graphql`
    query {
      strapiHome: allStrapiHomePage {
        nodes {
          vwoEnablePages {
            url
          }
          domain {
            name
          }
        }
      }
    }
  `)

  const filterByDomain: Queries.STRAPI_HOME_PAGE = strapiHome.nodes.find(
    (node: Queries.STRAPI_HOME_PAGE) => node.domain?.name === STRAPI_CONFIG.DOMAIN
  )

  if (filterByDomain.vwoEnablePages) {
    const vwoEnablePages = filterByDomain.vwoEnablePages.map((item) => item?.url)
    const vwoEnablePagesList = vwoEnablePages as string[]
    const vwoEnablePagesListFilter = vwoEnablePagesList.filter((item) => item !== undefined && item !== null)
    if (vwoEnablePagesListFilter.length > 0) {
      return vwoEnablePagesListFilter
    }
  }

  return null
}
